import React from "react"
import Navbar from "../../components/navbar"
import BackgroundShape from "../../components/backgroundShape"
import Container from "../../components/container"
import Header from "../../components/header"
import Head from "../../components/head"
import Layout from "../../components/layout"
import Button from "../../components/button"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import OnboardingCardSm from "../../components/onboardingCardSm"
import { Link } from 'gatsby'

const Shipping = () =>  {
    return (
        <Layout navbar="darkblue">
        <Head title="Order" description="Learn how to connect with multiple vendors, manage your inventory, sales, customers and more with our step-by-step tutorials"/>
            <BackgroundShape style={{shape: "clip-fixed-small", color: "darkblue", particles: true}}/>
                <Container style={{classes: "container" }}>
                <div className="position-relative">
                    <Header content={{title: "Orders management", text: "Find out how our customers use Onport to help increase their sales and grow their retail businesses"}} style={{color: "text-center"}}/>
                    <Link to='/onboarding' className='btnOn dark-bg' style={{position:'absolute', top: '246px', left:'-14px' }}>← Back</Link>
                </div>
                </Container>
                <Container style={{classes: "container mb-5" }}>
                    <div style={{background: '#F4F6F8', padding:'40px 0', margin:'2rem -15px 0 -15px'}} data-sal="slide-up" data-sal-delay='200' data-sal-easing="ease" data-sal-duration="300">
                        <p style={{fontWeight:'700', fontSize: "1rem", marginTop: '8px', textAlign:'center'}}>Do your <span style={{color:'#3b77ce'}}>vendors</span> have an ecommerce platform?</p>
                        {/* <p style={{fontWeight:'300', marginTop: '8px', textAlign:'center'}}>Lorem ipsum dolor sit amet</p> */}
                        <div className="row align-items-start justify-content-around mt-5 w-75 mx-auto">
                            <div className="text-center col-12 col-md-6">
                                <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0rem auto 2rem auto', padding:'8px 16px', width:'60px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>Yes</p>
                                <p style={{fontWeight:'600', textAlign:'center', fontSize:'18px', marginTop: '8px', marginBottom:'2rem', padding:'0 16px'}}>↓</p>
                                <img src='https://taact-brand-assets.netlify.app/assets/icons/onport/website.svg' style={{height:'48px', marginBottom: '16px'}} data-sal="slide-up" data-sal-delay='400' data-sal-easing="ease" data-sal-duration="300"/>
                                <a className="btn-onboarding" target="_blank" href="https://help.jetti.io/hc/en-us/sections/4405433157522-Integrations">
                                    <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0 auto', padding:'8px 16px', width:'140px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>Vendor store + Vendor Portal</p>
                                </a>
                                <p style={{fontWeight:'300', marginTop: '8px', padding:'0 16px'}}>Vendors will receive the orders directly in a supported ecommerce store and use the vendor portal</p>
                            </div>
                            <div className="text-center col-12 col-md-6">
                                <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0rem auto 2rem auto', padding:'8px 16px', width:'60px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>No</p>
                                <p style={{fontWeight:'600', fontSize:'18px', marginTop: '8px', marginBottom:'2rem', padding:'0 16px'}}>↓</p>
                                <img src='https://taact-brand-assets.netlify.app/assets/icons/onport/mail.svg' style={{height:'48px', marginBottom: '16px'}} data-sal="slide-up" data-sal-delay='400' data-sal-easing="ease" data-sal-duration="300"/>
                                <a className="btn-onboarding" target="_blank" href="https://help.jetti.io/hc/en-us/articles/360021087580-Vendor-Portal-Overview">
                                    <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0 auto', padding:'8px 16px', width:'140px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>Vendor Portal</p>
                                </a>
                                <p style={{fontWeight:'300', marginTop: '8px', padding:'0 16px'}}>Vendors will receive the orders in the vendor portal and get notified by email</p>
                            </div>
                        </div>
                    </div>
                    <p style={{fontWeight:'700', fontSize: "1rem", marginTop: '2rem', textAlign:'center'}}>What happens to the <span style={{color:'#3b77ce'}}>order</span> once placed in your store?</p>
                    <div className="row my-5">
                        <div className="col-12 col-md-3">
                            <div className="shadow px-3 rounded text-center pt-4" style={{height:'180px', color: 'white', background: '#3b77ce'}}>
                                <div className="d-flex align-items-end justify-content-around w-50 mx-auto">
                                    <p style={{fontWeight:'600', fontSize:'18px', marginRight:'16px'}}>1.</p>
                                    <img src='https://assets.onport.com/assets/icons/jetti/order-sync-reverse.svg' style={{height: '64px'}}/>
                                </div>
                                <p style={{fontWeight:'400', marginTop: '8px', padding: '12px 12px 0 12px', borderTop: '1px white solid'}}>The order syncs to Onport under the Sales section</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="shadow px-3 rounded text-center pt-4" style={{height:'180px', color: 'white', background: '#3b77ce'}}>
                                <div className="d-flex align-items-end justify-content-around w-50 mx-auto">
                                    <p style={{fontWeight:'600', fontSize:'18px', marginRight:'16px'}}>2.</p>
                                    <img src='https://assets.onport.com/assets/icons/jetti/automate-reverse.svg' style={{height: '64px'}}/>
                                </div>
                                <p style={{fontWeight:'400', marginTop: '8px', padding: '12px 12px 0 12px', borderTop: '1px white solid'}}>A dropship purchase will be automatically generated for each dropship provider</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="shadow px-3 rounded text-center pt-4" style={{height:'180px', color: 'white', background: '#3b77ce'}}>
                                <div className="d-flex align-items-end justify-content-around w-50 mx-auto">
                                    <p style={{fontWeight:'600', fontSize:'18px', marginRight:'16px'}}>3.</p>
                                    <img src='https://assets.onport.com/assets/icons/jetti/routing-reverse.svg' style={{height: '64px'}}/>
                                </div>
                                <p style={{fontWeight:'400', marginTop: '8px', padding: '12px 12px 0 12px', borderTop: '1px white solid'}}>The order is sent to the vendor's store and/or the vendor portal</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="shadow px-3 rounded text-center pt-4" style={{height:'180px', color: 'white', background: '#3b77ce'}}>
                                <div className="d-flex align-items-end justify-content-around w-50 mx-auto">
                                    <p style={{fontWeight:'600', fontSize:'18px', marginRight:'16px'}}>4.</p>
                                    <img src='https://assets.onport.com/assets/icons/jetti/reasons-reverse.svg' style={{height: '64px'}}/>
                                </div>
                                <p style={{fontWeight:'400', marginTop: '8px', padding: '12px 12px 0 12px', borderTop: '1px white solid'}}>The vendor enters tracking details, prints the label and packing slip and the order is marked as shipped!</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                <OnboardingCardSm content={{
                    slug: 'shipping', 
                    category: 'SHIPPING', 
                    step:'← Back', 
                    src: "https://taact-brand-assets.netlify.app/assets/illustrations/send.svg", 
                    title: "Shipping strategies", 
                }}/>
                <OnboardingCardSm content={{
                    slug: 'returns', 
                    category: 'RETURNS',
                    step:'Next →', 
                    src: "https://taact-brand-assets.netlify.app/assets/illustrations/send-order.svg", 
                    title: "Returns", 
                }}/>
                </div>
                <div className="col-12 col-md-6"></div>
                </Container>
        </Layout>
    )
}

export default Shipping